/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CartCoupon {
    &-Message {
        display: inline-block;
    }

    &-Input {
        width: 100%;

        input {
            width: 100%;
            height: 48px;
        }

        @include mobile {
            width: 100%;
            margin-block-start: 0;
        }

        @include tablet-portrait {
            width: 100%;
            margin-block-start: 0;
        }
    }

    &-Button {
        @include button;

        background-color: $black;
        color: $white;
        width: auto !important;

        &[disabled] {
            .CartPage-Discount & {
                opacity: 1;
            }
        }

        &_isHollow {
            background-color: $black;
            color: $white;
        }
    }

    &-Title {
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        margin-block-end: 20px;
    }
}
