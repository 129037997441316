/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.CartCoupon {
  &-Message {
    display: flex;
    .coupons {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 10px 0;

      &:empty {
        display: none;
      }
       .coupon{
         display: inherit;
         p.close {
           cursor: pointer;
           margin-left: 20px;
         }
       }
      p {
        margin: 0;
      }
    }
    .button-container {
      display: flex;
      flex-direction: column;
      .CartCoupon-Button {
        margin-top: 10px;
      }
    }
  }
}
