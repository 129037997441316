/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-item-subheading-color: #888;
}

.CartItem {
    --header-color: var(--color-black);
    border-block-end: 1px solid $grey;

    &-OutOfStock {
        color: $errorred;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-block-end: 8px;
    }

    &-Link {
        color: inherit;
        font-weight: 400;
        align-self: start;

        &:hover {
            text-decoration: none;
        }
    }

    &_isCartOverlay:last-of-type {
        border-block-end: none;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Wrapper {
        display: grid;
        grid-gap: 12px;
        padding: 12px;
        background: var(--color-white);
        align-items: center;
        grid-template-columns: minmax(100px, 3fr) 2fr 1fr;

        &_isMobileLayout {
            grid-template-columns: 96px auto;
            grid-gap: 12px;
            padding: 24px 0;
            min-height: 130px;
        }

        &_isSummary {
            grid-template-columns: 96px auto;

            & .CartItem-ProductInfo {
                justify-content: space-between;
            }
        }

        &_isCart {
            .CartPage & {
                display: flex;
                flex-direction: column;
                padding: 24px 0 28px;
                grid-gap: 0;

                @include desktop {
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 20px;
                    padding: 28px 16px;
                }

                @include wide-desktop {
                    gap: 50px;
                }
            }

            & .CartItem-Title {
                padding-inline-start: 10px;

                @include desktop {
                    padding-inline-start: 20px;
                }
            }
        }

        &_isProductOutOfStock {
            .CartItem-ProductActions_isMobileLayout {
                align-self: flex-end;
                width: 100%;
            }
        }
    }

    &-ProductInfo {
        display: flex;

        .Checkout & {
            align-items: flex-end;
            gap: 10px;
        }

        &_isMobileLayout {
            align-items: flex-start;
            justify-content: space-between;
        }

        &_Right,
        &_Left {
            width: 100%;

            @include desktop {
                width: 50%;
            }
        }

        &_Right {
            position: static;

            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 15px;
            }
        }
    }

    &-Options {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;

        @include mobile {
            padding-block-end: 12px;
        }

        .Checkout & {
            padding-bottom: 0;
        }
    }

    &-Option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 0 4px;

        strong {
            margin-right: 8px;

            @include desktop {
                margin-right: 12px;
            }
        }
    }

    &-ItemLinks {
        &Wrapper {
            display: flex;
            flex-direction: column;
            color: var(--secondary-dark-color);
            font-size: 12px;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 65%;
        overflow: hidden;

        .Checkout & {
            @include tablet {
                width: auto;
            }
        }

        &_isMobileLayout {
            width: 90%;
        }

        .SampleProductCategoryPage & {
            width: 100%;
        }
    }

    &-Heading,
    &-SubHeading {
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        margin-block-end: 6px;

        @include mobile {
            max-width: 95%;
            margin-block-end: 2px;
            margin-block-start: 2px;
        }
    }

    &-Brand {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 2px;

        .CartPage & {
            margin-bottom: 4px;
        }
    }

    &-Sku {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 12px;

        @include desktop {
            margin-bottom: 8px;
        }
    }

    &-Heading {
        color: var(--color-black);
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 4px;

        .CartPage & {
            margin-bottom: 8px;

            @include desktop {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
        }
    }

    &-SubHeading {
        color: var(--cart-item-subheading-color);
    }

    &-Picture {
        &.Image {
            width: 96px;
            height: 96px;
            align-self: flex-start;
            vertical-align: top;

            .CartPage & {
                width: 80px;
                height: 80px;

                @include desktop {
                    width: 112px;
                    height: 112px;
                }
            }

            .Checkout & {
                width: 80px;
                height: 80px;
            }
        }

        img {
            object-position: top;
        }

        &_isMobileLayout {
            &.Image {
                width: 96px;
                height: 96px;
            }
        }
    }

    &-Price-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .Checkout & {
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .SampleProductCategoryPage & {
            margin-top: 5px;

            @include mobile {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                grid-column-gap: 13px;
            }
        }

        .CartItem-Wrapper_isMobileLayout & {

            @include desktop {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                grid-column-gap: 13px;
            }
        }

        @include desktop {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            grid-column-gap: 18px;
        }
    }

    &-Price {
        text-align: end;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        &_isMobileLayout {
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 700;
            font-size: 16px;
        }

        &_isCartOverlay {
            flex-direction: row;
            width: 100%;
        }

        &_isCartOverlay data {
            font-size: 14px;
            display: inline-flex;
        }

        &_isUnitCalcModifierActive {
            display: contents;
        }

        &_isCheckout {
            display: flex;
        }

        .UnitValue {
            display: inline-flex;
            margin-left: auto;
        }

        .value {
            font-weight: 400;
            font-style: italic;
            font-size: 11px;
        }
    }

    &-Quantity {
        .Checkout & {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;

            strong {
                margin-right: 12px;
            }
        }
    }

    &-QuantityWrapper {
        .CartItem-Wrapper_isMobileLayout & {
            @include desktop {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 8px;
                margin-top: 4px;
            }
            &::before {
                @include desktop {
                    content: attr(data-label);
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.0008em;
                }
            }
        }

        &_isPlaceholder {
            .CartItem-Wrapper_isMobileLayout & {
                @include desktop {
                    margin: 0;
                }

                &::before {
                    @include desktop {
                        display: none !important;
                    }
                }
            }
        }

        &.no-stock {
            cursor: pointer;
            border: 1px solid $black;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-align: center;
            padding: 4px 8px;
            min-width: 199px;

            @include mobile {
                margin-left: -90px;
            }

            &:hover {
                background: $black;
                color: $white;
                text-decoration: none;
            }
        }
    }

    &-AdditionalActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 16px;

        @include desktop {
            gap: 25px;
            padding-top: 0;
        }
    }

    &-ProductActions {
        display: flex;
        align-items: center;

        &_isMobileLayout {
            justify-content: space-between;

            @include desktop {
                display: block;
                align-items: flex-end;
            }
        }

        .CartPage & {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-height: 48px;

            @include mobile {
                padding-left: 90px;
            }
        }
        .free-gift {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-CartItemRows {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
    }

    &-Delete {
        display: flex;
        z-index: 5;
        font-size: 14px;
        margin-inline-start: 8px;

        .SampleProductCategoryPage & {
            align-self: flex-start;
            flex-shrink: 0;
        }

        &:hover {
            color: var(--primary-base-color);

            .CloseIcon {
                fill: var(--primary-base-color);
            }
        }

        &_isMobileLayout {
            height: 16px;
            width: 16px;
            padding-inline-start: 0;
            margin-inline-end: 8px;

            .CartPage & {
                @include mobile {
                    position: absolute;
                    top: 24px;
                    right: 0;
                    margin: 0;
                }
            }
        }
    }

    &-DeleteButtonText {
        line-height: 24px;
        margin-inline-start: 4px;

        &_isMobileLayout {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include narrow-desktop {
            display: none;
        }

        .CartPage & {
            display: none;
        }
    }

    & &-Qty { // hack to obtain required specificity
        margin-block-start: 0;
        display: flex;

        .CartPage & {
            border: 1px solid $black;
        }

        @include mobile {
            align-items: center;
            font-size: 14px;
        }

        &[type='number'] {
            border-color: transparent;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
        }

        input {
            @include mobile {
                width: 32px;
                min-width: 32px;
                background-color: transparent;
            }
        }

        button {
            cursor: pointer;
            border: 0 none;
            width: 38px;
            height: 46px;
        }
    }

    &-SwipeToDeleteRightSide {
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: var(--color-white);
        background-color: var(--swipe-to-delete-bg-color);
    }

    .ProductPrice {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;

        @include desktop {
            min-height: auto;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        &-SubPrice {
            font-size: 12px;
            font-weight: 400;
        }

        .CartPage & {
            @include desktop {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    .CartItem-OldPrice {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        text-decoration: line-through;
    }

    .CartItem-QuantityWrapper {
        &_isCartOverlay {

            & button {
                border: 0 none;
                width: 16px;
                height: 16px;
                order: 1 !important;
                margin-right: 8px;
            }

            .CartItem-Qty {
                font-size: 14px;
                flex-direction: row-reverse;

                input {
                    width: 29px;
                    min-width: 29px;
                    height: 16px;
                    min-height: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: -0.0008em;
                }
            }
        }
    }

    &_limitation {
        margin-bottom: 8px;

        .label {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.0008em;
            font-style: italic;
        }
}
}