/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-page-divider-background: #d8d8d8;
    --cart-page-promo-background: #fff;
    --cart-page-table-head-background: var(--secondary-base-color);
}

.CartPage {
    margin-block-end: var(--footer-total-height);

    @include mobile {
        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;
    }

    &-Wrapper {
        &.ContentWrapper {
            @include desktop {
                display: flex;
                gap: 48px;
                justify-content: space-between;
                width: 100%;
            }

            @include ultra-narrow-desktop {
                display: block;
            }

            @include mobile {
                padding: 0;
            }
        }

        &.show-lg {
            @media (min-width: 1265px) {
                display: none !important;
            }
        }
    }

    &-CrossSell {
        overflow: hidden;

        .CartPage-Static & {
            @media (max-width: 1265px) {
                display: none !important;
            }
        }

        .ContentWrapper {
            @media (max-width: 1111px) {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .ProductLinks-Slider .ProductCard {
            @media (min-width: 1265px) and (max-width: 1565px) {
                padding: 0 5px;
            }
        }
    }

    &-Empty {
        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--expandable-content-divider-color);
            text-align: center;
        }
    }

    &-Summary {
        margin-bottom: 32px;

        @include mobile {
            inset-inline-start: 0;
            width: 100%;
        }

        .CheckoutOrderSummary {
            border: 0 none;
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        inset-inline-start: -16px;
        width: calc(100% + 32px);
    }

    &-CheckoutButton {
        &.Button {
            margin-block-start: 10px;
            margin-block-end: 10px;
            width: 100%;
            text-align: center;
        }
    }

    &-Promo,
    &-Total {
        min-height: 48px;

        @include mobile {
            min-height: 0;
        }
    }

    &-Items {
        margin-bottom: 32px;

        @include desktop {
            margin-bottom: 24px;
        }
    }

    &-Promo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 24px 30px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        .FreeShippingPromotions-Promo {
            width: 100%;
            text-align: center;
        }

        &Block {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--cart-page-divider-background);
            padding: 24px 12px;

            @include mobile {
                margin-block-start: 12px;
                padding: 14px;
                border: 1px solid var(--primary-divider-color);
            }
        }

        &Image {
            width: 46px;
            margin-inline-end: 6px;

            @include mobile {
                width: 36px;
                margin-inline-end: 7px;
            }
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    &-DiscountSection {
        border-top: 8px solid $white;
        padding: 4px 16px;
        margin-right: -16px;
        margin-left: -16px;

        @include desktop {
            padding: 4px 24px 6px;
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    &-Discount {
        &.ExpandableContent {
            border-block-start: 0;

            &:not(:last-child) {
                border-bottom: 1px solid $grey;
            }

            &:last-of-type {
                border-bottom: 0;
            }

            .Field {
                margin: 0;
            }

            // form {
            //     display: flex;
            //     align-items: flex-start;
            //     gap: 12px;
            // }
        }
    }

    &-Static {
        @include desktop {
            width: calc(100% - 408px);
        }

        @include wide-desktop {
            width: calc(100% - 467px);
        }

        @include ultra-narrow-desktop {
            width: 100%;
        }
    }

    &-Floating {
        background: $icegrey;
        padding-right: 24px;
        padding-left: 24px;

        @include mobile {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            width: 360px;
            align-self: flex-start;
        }

        @include wide-desktop {
            width: 419px;
        }

        @include ultra-narrow-desktop {
            width: 100%;
        }

        &Title {
            border-bottom: 1px solid $grey;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            padding: 24px 0 16px;
            margin-bottom: 24px;
        }
    }

    &-Heading {
        margin-block-start: 24px;
        margin-block-end: 16px;
        border-bottom: 1px solid $grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;

        @include desktop {
            margin-block-end: 4px;
        }

        h1 {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;

            @include wide-desktop {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &-ProductsCount {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        @include wide-desktop {
            padding-right: 16px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: none;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }

    }

    .ProductLinks {
        &-Slider {
            margin-right: 0;
            margin-left: 0;

            // @include desktop {
            //     margin-left: -14px;
            //     width: calc(100% - 394px);
            // }

            // @include wide-desktop {
            //     width: calc(100% - 453px);
            // }

            // @include ultra-narrow-desktop {
            //     width: 100%;
            // }

            &.slick-slider {
                .slick-prev {
                    @include desktop {
                        left: -2px;
                        z-index: 1;
                    }
                }

                .slick-next {
                    @include desktop {
                        right: -2px;
                        z-index: 1;
                    }
                }
            }

            .ProductCard {
                @include desktop {
                    padding: 0 12px;
                }

                &-PriceWrapper {
                    .ProductPrice-PriceBadge {
                        @include desktop {
                            font-size: 11px;
                            margin-right: 2.6%;
                        }
                    }
                }
            }

            .ProductPrice {
                @include desktop {
                    font-size: 13px;
                }

                del {
                    @include desktop {
                        font-size: 11px;
                        margin-right: 2.6%;
                    }
                }

                &-DiscountPercentage {
                    @include desktop {
                        padding: 2px;
                    }
                }

                &-Price {
                    @include desktop {
                        margin-right: 2.6%;
                    }
                }
            }
        }

        &-Title {
            padding: 0;
            text-align: left;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;
            margin-bottom: 24px;
            margin-right: 0;
            margin-left: 0;

            @include desktop {
                margin-bottom: 16px;
            }

            @include ultra-narrow-desktop {
                margin-top: 48px;
            }

            @include mobile {
                margin-top: 48px;
            }

            &:before {
                display: none;
            }

            span {
                padding: 0;
            }
        }
    }

    &-GiftBox {
        padding: 16px 0;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;

        &Button {
            &.Button {
                background: none;
                color: $black;
                border: 0 none;
                height: auto;
                padding: 0;
                gap: 12px;
                justify-content: flex-start;

                &:not([disabled]):hover {
                    background: none;
                    color: $black;
                    height: auto;
                    padding: 0;
                }

                svg {
                    border: 1px solid #D9D9D9;
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                }
            }
        }

        &_choose_bag {
            .ProductCard-Brand,
            .ProductCard-Reviews,
            .ProductWishlistButton {
                display: none !important;
            }

            &.ProductListWidget-Page {
                padding: 0;
                grid-template-columns: repeat(3, 1fr);
                gap: 32px;

                @include mobile {
                    display: block;
                }
            }

            .ProductCard {
                &_layout_grid {
                    @include desktop {
                        margin-bottom: 0;
                    }

                    &:not(:last-child) {
                        @include mobile {
                            border-bottom: 1px solid $grey;
                            padding: 8px 0;
                        }
                    }
                }

                &-Link {
                    @include mobile {
                        gap: 12px;
                    }
                }

                &-FigureReview {
                    padding-bottom: 10px;

                    @include mobile {
                        padding-bottom: 0;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;
                    }
                }

                &-Figure {
                    padding-top: 0;
                    padding-bottom: 0;

                    @include mobile {
                        width: 60px;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                }

                &-Picture {
                    @include mobile {
                        padding-bottom: 50px;
                    }

                    img {
                        @include mobile {
                            height: 100% !important;
                            width: auto !important;
                            max-width: 100%;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }

                &-Name {
                    font-weight: 600;

                    @include mobile {
                        height: auto;
                        margin: 0;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                &-Content {
                    @include mobile {
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                &-PriceWrapper {
                    min-height: 0;

                    @include desktop {
                        min-height: 30px;
                    }

                    .ProductPrice {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.2px;

                        @include desktop {
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: -0.0008em;
                            min-height: 30px;
                        }
                    }
                }

                .AddToCart {
                    background: $black;
                    color: $white;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.04em;
                    min-width: 158px;
                    height: 32px;
                    width: auto;
                    padding: 0 5px;

                    &:not([disabled]):hover {
                        @include desktop {
                            background: $white;
                            color: $black;
                            height: 32px;
                            padding: 0 5px;
                        }
                    }

                    &.outofstock {
                        display: none;
                    }
                }
            }
        }

        &_choose_card {
            &.slick-slider {
                .slick-arrow {
                    z-index: 1;

                    @include mobile {
                        display: block !important;
                    }
                }

                .slick-prev {
                    @include desktop {
                        left: 0;
                    }
                }

                .slick-next {
                    @include desktop {
                        right: 0;
                    }
                }
            }

            .ProductCard {
                &:last-child {
                    margin-bottom: 0;
                }

                &-Picture {
                    padding-bottom: 132px;

                    @include desktop {
                        padding-bottom: 166px;
                    }

                    img {
                        width: auto !important;
                        max-width: 100%;
                        height: 100% !important;
                        right: 0;
                        margin: 0 auto;
                    }
                }

                &-Figure {
                    padding: 33px;
                }

                &-FigureReview {
                    padding: 0;
                }

                &-Name {
                    font-family: $font-BlackerProDisplay;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: -0.003em;
                    text-align: center;
                    margin: 16px 0 0;
                }

                &-Content {
                    display: block;

                    @include desktop {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                        border: 1px solid $grey;
                        padding: 12px 16px;
                    }
                }

                &-PriceWrapper {
                    min-height: 0;
                    margin-bottom: 12px;
                    text-align: center;

                    @include desktop {
                        order: 1;
                        margin-bottom: 0;
                    }
                }

                .ProductPrice {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;

                    @include desktop {
                        min-height: 0;
                    }
                }

                .ProductActions-Qty {
                    margin: 0;
                    border: 1px solid $black;

                    &[type='number'] {
                        border-color: transparent;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .AddToCart {
                    float: right;
                    width: auto;
                    min-width: 126px;
                    background: $black;
                    color: $white;

                    @include desktop {
                        order: 2;
                    }

                    &:not([disabled]):hover {
                        @include desktop {
                            background: $white;
                            color: $black;
                        }
                    }
                }

                [type='number'] ~ button {
                    border: 0 none;
                    width: 34px;
                    height: 46px;
                }
            }
        }
    }

    &-ContinueButton {
        margin-bottom: 44px;

        @include desktop {
            margin-bottom: 48px;
        }

        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            padding: 11px 24px;
            display: inline-flex;
            align-items: center;
            border: 1px solid $black;

            &:before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-width: 1px;
                border-style: solid;
                border-color: transparent $black $black transparent;
                transform: rotate(135deg);
                margin-right: 16px;
            }

            &:hover {
                background-color: $black;
                color: $white;
                text-decoration: none;

                &:before {
                    border-color: transparent $white $white transparent;
                }
            }
        }
    }
}

.ProductEditButton {
    display: inline-block;
    height: var(--wishlist-heart-size);
    width: var(--wishlist-heart-size);

    .CartPage & {
        width: auto;
        height: auto;
        padding-right: 16px;

        @include mobile {
            border-right: 1px solid $grey;
            padding: 4px 14px 4px 0;
        }
    }

    &-Button {
        background-color: unset;
        border: none;
        height: var(--wishlist-heart-size);
        padding: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;

        &:hover,
        &:focus {
            background-color: unset;
            height: var(--wishlist-heart-size);
            padding: 0;
        }

        .CartPage & {
            background: none;
            color: $black;
            height: auto;
            display: flex;
            align-items: center;
            gap: 13px;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.GiftBoxPopup {
    .Popup {
        &-Header {
            display: block;
            margin-bottom: 24px;
            padding: 0;
        }

        &-Content {
            overflow-x: hidden;
            padding: 24px 16px;

            @include desktop {
                padding: 24px;
                max-width: 596px;
            }

            .ProductList {
                margin-bottom: 8px;

                @include desktop {
                    margin-bottom: 24px;
                    padding-bottom: 0;
                }

                h2 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    margin: 0;
                }
            }
        }

        &-Heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin-bottom: 0;

            @include desktop {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &.Popup {
        .Popup-CloseBtn {
            position: absolute;
            z-index: 5;
            top: -3px;
            right: -20px;

            .CloseIcon {
                margin-inline-end: 7px;
                margin-block-start: 5px;
            }
        }
    }

    &_isVisible {
        &.Popup {
            @include mobile {
                top: 0;
                height: 100%;
            }
        }
    }

    .RenderWhenVisible {
        + .RenderWhenVisible {
            .ProductListWidget {
                border-top: 1px solid $grey;
                padding-top: 16px;
            }
        }
    }
}
