/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-overlay-totals-background: var(--secondary-base-color);
    --cart-overlay-divider-background: #d8d8d8;
}

.CartOverlay {
    &.Overlay {
        cursor: auto;

        @include desktop {
            position: fixed;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-end: 0;
            transform: translate3d(105%, 0, 0);
            transition: transform 0.3s;
            padding: 0;
            width: 453px;
            padding: 83px 32px 140px;
        }
    }

    &_isVisible {
        &.Overlay {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &-Empty {
        padding: 16px;
        text-align: center;
    }

    &-Actions {
        display: flex;
        flex-direction: column;
        padding: 16px 0 0;

        @include mobile {
            padding: 14px;
        }

        @include desktop {
            position: absolute;
            bottom: 0;
            left: 32px;
            right: 32px;
            padding-bottom: 24px;
        }

        .Button {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.04em;
            background: $black;
            border-color: $black !important;
            color: $white;

            &:hover {
                background: $white;
                color: $black;
            }

            &.CartOverlay-CartButton {
                background: $white !important;
                border-color: $white !important;
                color: $black !important;

                &:hover {
                    border-color: $black !important;
                    background: $black !important;
                    color: $white !important;
                }

                @include mobile {
                    display: none;
                }
            }
        }
    }

    &-TitleContainer {
        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 0;
            left: 32px;
            right: 32px;
            padding: 24px 0 8px;
            background: $white;
            border-bottom: 1px solid $grey;
            z-index: 1;
        }

        .CartOverlay-Title {
            @include desktop {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;
                margin: 0;
            }
        }
    }

    &-Close {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    &-OutOfStockProductsWarning {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        color: var(--color-white);
        font-size: 14px;
        font-weight: 600;
    }

    &-CheckoutButton {
        margin-block-start: 4px;

        @include mobile {
            margin-inline-start: 16px;
            width: 100%;
            display: block;
            text-align: center;
        }

        @include desktop {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-Promo,
    &-Total {
        font-weight: 600;

        @include mobile {
            min-height: 56px;
        }
    }

    &-Promo {
        padding: 16px 12px 23px;
        text-align: center;
        background: $icegrey;
        margin-block-end: 0;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-block-end: 8px;

        strong {
            margin: 0 5px;
        }
    }

    &-Items {

    }

    &-ContentWrapper {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &-Additional {
        padding:  36px 0 0;
    }

    &-Tax {
        font-size: 14px;
    }

    &-Total,
    &-Tax,
    &-Discount {
        display: flex;
        justify-content: space-between;
        padding-inline: 8px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;

        background: var(--cart-overlay-totals-background);

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 7px;
            padding-inline: 14px;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-Total {
        font-weight: bold;
        font-size: 18px;
    }
}
